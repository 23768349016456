import React, { useState } from 'react';
import QuestionList from './QuestionList';
import '../styles/App.css';
import { defaultEmotions, serverUrl } from '../Utils/storage';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './createSyrvey.css'
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'

function generateSurveyHash(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let hash = '';
  for (let i = 0; i < length; i++) {
    hash += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return hash;
}


export function SurveyCreationPage() {
  let navigate = useNavigate();


  const [questions, setQuestions] = useState([{ type: "text", answer: "", emotions: defaultEmotions }]); // Initialize with one empty question
  const [quizTitle, setQuizTitle] = useState('');
  const [quizDescription, setQuizDescription] = useState('');

  const validateForm = () => {
    for (let question of questions) {
      if (question.type === 'text' && question.answer.trim() === "") {
        return { isValid: false, message: "Please fill in all text questions before submitting." };
      } else if ((question.type === 'image' || question.type === 'video') && !question.answer.startsWith('http')) {
        return { isValid: false, message: `Please enter a valid ${question.type} URL starting with http or https.` };
      } else if (question.emotions.length < 3) {
        return { isValid: false, message: "Please select at least 3 emotions for each question." };
      }
    }
    return { isValid: true, message: "" };
  };
  
  const handleSubmit = async () => {
    const validation = validateForm();
    if (validation.isValid) {
      // Generate survey hash
      const hash = generateSurveyHash(8);
  
      // Retrieve authToken from localStorage
      const authToken = localStorage.getItem('authToken');
  
      // If authToken exists, make the request to the server
      if (authToken) {
        try {
          // Make a POST request to your server endpoint using async/await
          const response = await axios.post(serverUrl + '/survey/new', {
            questions: questions,
            hash: hash,
            title: quizTitle,
            description: quizDescription,
          }, {
            headers: {
              Authorization: `Bearer ${authToken}`, // Include bearer token in the headers
            },
          });
          // Handle response from server
          console.log('Survey submitted successfully:', response.data);
          navigate('/create-survey/success', { state: { hash: hash } });
        } catch (error) {
          // Handle errors
          console.error('Error submitting survey:', error);
        }
      } else {
        console.error('Auth token not found in localStorage');
        navigate('/')
        
      }
    } else {
      console.error('Form validation failed');
      alert(validation.message);
    }
  };

  // Add a function to handle adding new questions
  const handleAddQuestion = () => {
    setQuestions([...questions, { answer: "", type: "text", emotions: defaultEmotions }]);
  };

  return (
    <div className='base-unfixed-footer'>
    <div className='base-unfixed-footer-content'>
    <div className='CreateSyrveyPage'>

    <div className="survey-creation-page">
      {/* Input fields for quiz title and description */}


      <div className='syrvey-top-container'> <div className='syrvey-top'>
      <div className='syrvey-top-text'><a>Poll title:</a></div>
      <input
        type="text"
        placeholder="Enter poll title"
        value={quizTitle}
        onChange={(e) => setQuizTitle(e.target.value)}
      />

      <div className='syrvey-top-text'><a>Poll description:</a></div>
      <textarea
        placeholder="Enter poll description"
        value={quizDescription}
        onChange={(e) => setQuizDescription(e.target.value)}
      ></textarea>
      </div></div>
  
      {/* Removed SurveySettings component */}
      <div className='syrvey-questions-container'>

      <div className='syrvey-questions'>
      <QuestionList
        questions={questions}
        setQuestions={setQuestions}
      />


      </div>
      </div>
  
      {/* Button for adding questions */}
      <div className="syrvey-add-submit-container">
        <button onClick={handleAddQuestion} className="add-question-button">
          + ADD QUESTION
        </button>
    
        <button onClick={handleSubmit} className="submit-survey-button">
          Submit Poll
        </button>
      </div>
    </div>

    
    </div></div>

    <TopPanelBase/>
    <BotPanel is_fixed={false}/>
    </div>
  );
  
}
