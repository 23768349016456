import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './surveyPage.css';
import { serverUrl } from '../Utils/storage';
import axios from 'axios';
import Question from './Question';
import TopPanelBase from '../Components/TopPanel/TopPanelBase'
import BotPanel from '../Components/BottomPanel/BotPanel'

function SurveyPage() {
  const { surveyHash } = useParams();
  const [survey, setSurvey] = useState(null);
  const [emotions, setEmotions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // New state for validation message

  const navigate = useNavigate();

  useEffect(() => {
    const fetchSurvey = async () => {
      try {
        const response = await axios.get(`${serverUrl}/survey/take/${surveyHash}`);
        if (response.status !== 200) {
          throw new Error('Survey not found');
        }
        const data = response.data;
        setSurvey(data);

        const initialEmotions = {};
        data.questions.forEach(question => {
          const questionEmotions = {};
          question.emotions.forEach(emotion => {
            questionEmotions[emotion] = 0; 
          });
          initialEmotions[question.question_id] = questionEmotions;
        });
        setEmotions(initialEmotions);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSurvey();
  }, [surveyHash]);

  function handleEmotionChange(questionId, emotion, value) {
    setEmotions(prev => ({
      ...prev,
      [questionId]: {
        ...prev[questionId],
        [emotion]: value
      }
    }));
  }

  // Validation function
  function validateResponses() {
    for (const questionId in emotions) {
      const emotionValues = Object.values(emotions[questionId]);
      const sum = emotionValues.reduce((acc, val) => acc + val, 0);
  
      // If all responses for a question sum to 0, show error and return false
      if (sum === 0) {
        setErrorMessage("Each question must have at least one non-zero response.");
        return false;
      }
    }
    setErrorMessage("Fill all responses"); // Clear error message if validation passes
    return true;
  }

  async function submitSurvey() {
    // Check validation before submission
    if (!validateResponses()) {
      return;
    }

    setIsSubmitting(true);
    try {
      const authToken = localStorage.getItem('authToken');
      const response = await axios.post(
        `${serverUrl}/survey/submit`,
        { surveyHash, emotions },
        { headers: { Authorization: `Bearer ${authToken}` } }
      );

      if (response.data.success) {
        setSubmitted(true);
        navigate(`/survey-results/${survey.survey_id}`);
      }
    } catch (error) {
      console.error('Error submitting survey:', error);
    } finally {
      setIsSubmitting(false);
    }
  }

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!survey) return <div>No survey data available.</div>;

  return (
    <div className='base-unfixed-footer'>
      <div className='base-unfixed-footer-content'>
        <div className='survey-page-main'>
          <div className='survey-page-top'>
            <h1>{survey.title}</h1>
            <p>{survey.description}</p>
          </div>

          <div className='survey-page-question-container'>
            {survey.questions.map((question) => (
              <Question
                key={question.question_id}
                question={question}
                emotions={emotions[question.question_id] || {}}
                handleEmotionChange={handleEmotionChange}
              />
            ))}
          </div>

          {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Display validation error */}
          
          {!submitted && (
            <button onClick={submitSurvey} disabled={isSubmitting}>
              {isSubmitting ? 'Submitting...' : 'Submit Survey'}
            </button>
          )}
          {submitted && <div>Survey submitted successfully!</div>}
        </div>
      </div>
      <TopPanelBase />
      <BotPanel is_fixed={false} />
    </div>
  );
}

export default SurveyPage;